<template>
  <div class="page-wrapper calendar-view">
    <div class="authentification">
      <button class="btn" v-if="!authorized" @click="handleAuthClick">Connexion à l'agenda Google</button>
      <button class="btn" v-if="authorized" @click="fetchData">Récupérer les évènements</button>

      <button v-if="authorized" class="btn" @click="syncEvents">
        Synchroniser maintenant
      </button>
    </div>

    <div class="calendar-view__wrapper">
      <div
        v-for="(calendar, i) in calendars"
        :key="i"
        class="calendar-view__calendar"
      >
        <p>{{ calendar.name }}</p>
        <CalendarList
          v-if="calendar.events.length"
          :events="calendar.events"
          :bicycle="calendar.bicycle"
          :isMain="calendar.main"
        />
      </div>
    </div>

    <Toaster />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CalendarList from '@/components/Calendar/CalendarList';
import { Toaster } from '@/components/Utils';

export default {
  components: { CalendarList, Toaster },

  computed: {
    ...mapGetters({
      calendars: 'googleAgenda/calendars',
      authorized: 'googleAgenda/isGoogleApiAuthorized',
    }),

    next() {
      return this.calendars[this.current + 1] && this.calendars[this.current + 1].name || this.calendars[0].name;
    },
  },

  created() {
    this.handleClientLoad();
  },

  methods: {
    ...mapActions({
      handleClientLoad: 'googleAgenda/handleClientLoad',
      handleAuthClick: 'googleAgenda/handleAuthClick',
      fetchData: 'googleAgenda/fetchData',
      syncEvents: 'googleAgenda/syncEvents',
    }),
  },
}
</script>
