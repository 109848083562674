<template>
  <div class="calendar calendar--admin">
    <div v-for="(calendar, id) in events" :key="id" class="calendar__container">
      <CalendarItemList :events="calendar.events" :showUser="showUser">
        <h1 class="calendar__container__title">
          {{ calendar.day }} <span class="text text--small">{{ calendar.date }}</span>
        </h1>
        <p
          class="calendar__container__sub-title"
          v-html="corner[calendar.day]"
        ></p>
      </CalendarItemList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CalendarItemList from './CalendarItemList';

export default {
  components: {CalendarItemList},

  props: {
    events: {
      type: Array,
      default: () => [],
    },
    bicycle: String,
    isMain: Boolean,
    showUser: Boolean,
  },
  
  data() {
    return {
      currentDay: 0,
      corner: {
        lundi: '&bull;&nbsp;Lille-Sud &bull;&nbsp;Faches <br />&bull;&nbsp;Ronchin',
        mardi: '&bull;&nbsp;Vauban Esquermes &bull;&nbsp;Bois Blancs <br />&bull;&nbsp;Fg Béthune &bull;&nbsp;Lomme &bull;&nbsp;Lambersart',
        mercredi: '&bull;&nbsp;La Madeleine &bull;&nbsp;Saint André <br />&bull;&nbsp;St Maurice Pellevoisin',
        jeudi: '&bull;&nbsp;Hellemmes &bull;&nbsp;Fives <br />&bull;&nbsp;Mons-en-Baroeul',
        vendredi: '&bull;&nbsp;Wazemmes &bull;&nbsp;Moulins <br />&bull;&nbsp;Lille-centre &bull;&nbsp;Vieux-Lille',
      },
    };
  },

  computed: {
    ...mapGetters({
      users: 'users/availableUsers',
      usersByBicycle: 'users/getUserByBicycle',
      calendars: 'googleAgenda/calendars',
    }),
  },

  methods: {
    prettyDate(date) {
      return (new Date(date)).toLocaleString('fr-fr', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
  },
};
</script>
